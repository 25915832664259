export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [13,[2]],
		"/(app)/admin": [14,[2,3]],
		"/(app)/admin/settings": [15,[2,3]],
		"/(app)/ai": [16,[2,4]],
		"/(app)/ai/c/[id]": [17,[2,4]],
		"/(app)/ai/playground": [18,[2,4,5]],
		"/(app)/ai/workspace": [19,[2,4,6]],
		"/(app)/ai/workspace/documents": [20,[2,4,6]],
		"/(app)/ai/workspace/functions": [21,[2,4,6]],
		"/(app)/ai/workspace/functions/create": [22,[2,4,6]],
		"/(app)/ai/workspace/functions/edit": [23,[2,4,6]],
		"/(app)/ai/workspace/models": [24,[2,4,6]],
		"/(app)/ai/workspace/models/create": [25,[2,4,6]],
		"/(app)/ai/workspace/models/edit": [26,[2,4,6]],
		"/(app)/ai/workspace/prompts": [27,[2,4,6]],
		"/(app)/ai/workspace/prompts/create": [28,[2,4,6]],
		"/(app)/ai/workspace/prompts/edit": [29,[2,4,6]],
		"/(app)/ai/workspace/tools": [30,[2,4,6]],
		"/(app)/ai/workspace/tools/create": [31,[2,4,6]],
		"/(app)/ai/workspace/tools/edit": [32,[2,4,6]],
		"/auth": [41],
		"/error": [42],
		"/(app)/manage/laptops": [33,[2,7]],
		"/(app)/manage/laptops/dashboard": [34,[2,7]],
		"/(app)/manage/laptops/requests": [35,[2,7]],
		"/(app)/manage/lockers": [36,[2,8]],
		"/(app)/manage/lockers/dashboard": [37,[2,8]],
		"/(app)/manage/lockers/requests": [38,[2,8]],
		"/(app)/personal": [39,[2,9]],
		"/(app)/personal/requests": [40,[2,9]],
		"/public": [43,[10]],
		"/public/findmylocker": [44,[10,11]],
		"/public/forms/lockerim": [45,[10,12]],
		"/s/[id]": [46]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';